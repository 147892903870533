import styled, { css } from 'styled-components'
import { Popup } from 'semantic-ui-react'

export const CompleteMark = styled.i.attrs({
  className: 'ion ion-md-checkmark',
})`
  margin: auto;
`

export const CompleteMarkContainer = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  border: 1px dotted ${(props) => props.theme.cLightGrey};
  margin-left: auto;

  ${CompleteMark} {
    display: none;
  }

  ${(props) =>
    props.inProgress &&
    `
    border-style: solid;
    border-color: ${props.theme.cLighterGreen};
  `}

  ${(props) =>
    props.complete &&
    css`
      border-style: solid;
      border-color: ${props.theme.cLighterGreen};
      background: ${props.theme.cLighterGreen};

      ${CompleteMark} {
        display: block;
        color: ${props.theme.cDarkGreen};
      }
    `}

  ${(props) =>
    props.large &&
    css`
      width: 40px;
      height: 40px;

      ${CompleteMark} {
        font-size: 2em;
      }
    `}
`

export const CompleteMarkTooltip = styled(Popup)`
  margin-left: auto;
  padding-left: 8px;
`
