import colors from './_colors'

const theme = {
  cText: colors.black,
  cWhite: colors.white,
  cBlack: colors.black,
  cPrimary: colors.blue,
  cPrimaryDark: colors.blueDark,
  cPrimaryLight: colors.carolinaBlue,
  cWarning: colors.orangishRed,
  cWarningMid: colors.midRed,
  cWarningDark: colors.rustyRed,
  cGrey: colors.lightGrey,
  cLightGrey: colors.lightGrey,
  cGrey2: colors.grey2,
  cGrey3: colors.grey3,
  cProgressDone: colors.progressGreen,
  cYellow: colors.yellow,
  cYellowLight: colors.yellowLight,
  cLighterGreen: colors.lighterGreen,
  cSuccess: colors.freshGreen,
  cDarkGreen: colors.pine,
  cProgressAccentStart: colors.progressAccentStart,
  cProgressAccentStop: colors.progressAccentStop,
  cProgressRedStart: colors.progressRedStart,
  cProgressRedStop: colors.progressRedStop,
  cProgressBlueStart: colors.progressBlueStart,
  cProgressBlueStop: colors.progressBlueStop,

  headerHeight: 96,
  footerHeight: 56,

  // ...colors,
}

export default theme
