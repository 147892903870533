/* Libraries */
import axiosPublic from '../model/axios-public'

const fetchDataPublic = async (url, data) =>
  new Promise(async (resolve, reject) => {
    const parsedData = JSON.stringify(data)

    try {
      const result = await axiosPublic({
        method: 'POST',
        url,
        data: parsedData,
      })

      // console.log(result);

      resolve(result.data)
    } catch (error) {
      console.log(`Error: ${error}`)

      reject(error)
    }
  })

export default fetchDataPublic
