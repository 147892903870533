import styled from 'styled-components'
import rwd from 'styles/rwd'

export const StyledTabs = styled.div`
  position: relative;
  border-bottom: 1px solid ${(props) => props.theme.cLightGrey};
`

export const Scroll = styled.div`
  position: relative;
  overflow: hidden;
  overflow-x: auto;
`

export const TabsWrapper = styled.div`
  position: relative;
  white-space: nowrap;
  padding: 12px 0;

  ${rwd.Medium`
    padding: 24px;
  `}
`

export const LinkText = styled.span`
  display: block;
  position: relative;
  font-family: 'Open Sans';
  font-size: 11px;
  letter-spacing: 2px;
  line-height: 20px;
  text-transform: uppercase;
  padding: 8px 0;

  &:before {
    content: '';
    width: 100%;
    height: 8px;
    background-color: transparent;
    position: absolute;
    top: 100%;
    left: 0%;
    transition: background-color 0.2s;
  }
`

export const StyledTab = styled.button`
  display: inline-block;
  position: relative;
  cursor: pointer;
  outline: none;
  visibility: visible;
  padding: 12px;
  color: ${(props) => props.theme.cBlack};
  transition: opacity 0.2s;

  ${rwd.Medium`
    padding: 24px;
  `}

  ${rwd.Large`
    padding: 32px 40px;
  `}

  &:focus ,
  &:hover {
    opacity: 0.6;
  }

  ${(props) =>
    props.isActive &&
    `
    &:focus ,
    &:hover {
      opacity: 1;
    }

    ${LinkText} {
      font-weight: bold;

      &:before {
        background-color: ${props.theme.cPrimary}!important;
      }
    }
  `}
`
