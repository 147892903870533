import styled from 'styled-components'
import rwd from 'styles/rwd'
import backgroundImage from 'static/images/Pattern.png'

export const Container = styled.div`
  overflow: hidden;
  background-color: #fff;
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-position: 0% 100%;
  background-size: contain;
`

export const Offset = styled.div`
  position: relative;
  padding-top: 28px;
  padding-bottom: 76px;
  margin-right: 0;

  ${rwd.Medium`
    padding-top: 44px;
    padding-bottom: 60px;
    margin-right: 64px;
  `}
`

export const Wrapper = styled.div`
  margin-right: 0;

  ${rwd.Medium`
    margin-right: -50%;
  `}

  .slick-list {
    z-index: 0;
  }

  .slick-arrow {
    z-index: 1;
    width: 24px;
    height: 24px;

    &.slick-prev,
    &.slick-next {
      &:before {
        font-family: 'Ionicons';
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        text-rendering: auto;
        -moz-osx-font-smoothing: grayscale;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        text-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
        opacity: 1;
        color: ${(props) => props.theme.cBlack};
        background-color: transparent;
        transition: color 0.2s;
      }

      &:focus,
      &:hover {
        &:before {
          color: ${(props) => props.theme.cPrimary};
        }
      }
    }

    &.slick-prev {
      left: 4px;

      &:before {
        content: '\f3cf';
      }
    }

    &.slick-next {
      right: 4px;

      &:before {
        content: '\f3d1';
      }
    }
  }

  .slick-dots {
    z-index: 1;
    height: auto;
    top: 100%;
    margin-left: 8px;
    text-align: center;
    width: 100%;
    margin-top: 28px;

    ${rwd.Medium`
      margin-top: 20px;
      width: 66.67%;
    `}

    ${rwd.Large`
      text-align: right;
    `}

    li {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin: 0;

      button {
        display: block;
        padding: 8px;
        width: 24px;
        height: 24px;

        &:before {
          position: relative;
          content: '';
          display: block;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background-color: #e1e1e1;
          opacity: 1;
          transition: background-color 0.2s;
        }

        &:hover {
          &:before {
            background-color: #e1e1e1;
          }
        }
      }

      &.slick-active {
        button {
          &:before {
            background-color: #ee220d;
          }
        }
      }
    }
  }

  .slick-track {
    display: flex;
    align-items: stretch;
    margin: 2rem;

    @media screen and (max-width: 767px) {
      margin-left: 1rem;
    }
  }

  .slick-slide {
    height: auto;
    margin-right: 2rem;

    & > div {
      height: 100%;
    }
  }
`

export const NotificationSlide = styled.div`
  outline: 0;
  height: 100%;
  padding-left: 32px;
  padding-right: 32px;

  ${rwd.Medium`
    padding-left: 32px;
    padding-right: 0;
  `}
`
