import styled from 'styled-components'
import rwd from 'styles/rwd'

export const Container = styled.div`
  overflow: hidden;
`

export const Offset = styled.div`
  position: relative;
  padding-top: 28px;
  padding-bottom: 76px;
  margin-right: 0;

  ${rwd.Medium`
    padding-top: 32px;
    padding-bottom: 72px;
    margin-right: 64px;
  `}

  ${rwd.Large`
    padding-top: 64px;
    padding-bottom: 88px;
    margin-right: 128px;
  `}
`

export const Wrapper = styled.div`
  margin-right: 0;

  ${rwd.Medium`
    margin-right: -100%;
  `}

  .slick-list {
    z-index: 0;
  }

  .slick-arrow {
    z-index: 1;
    width: 32px;
    height: 48px;
    background-color: ${(props) => props.theme.cWarning};
    transition: background-color 0.2s;

    ${rwd.Large`
      width: 64px;
    `}

    &.slick-prev ,
    &.slick-next {
      top: 100%;
      right: 50%;
      margin-top: 40px;
      transform: none;

      ${rwd.Medium`
        margin-top: 24px;
      `}

      ${rwd.Large`
        margin-top: 40px;
      `}

      &:before {
        font-family: 'Ionicons';
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        text-rendering: auto;
        -moz-osx-font-smoothing: grayscale;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        opacity: 1;
        color: ${(props) => props.theme.cWhite};
      }

      &:focus,
      &:hover {
        background-color: ${(props) => props.theme.cWarningDark};
      }
    }

    &.slick-prev {
      left: auto;
      margin-right: -32px;

      ${rwd.Large`
        margin-right: -64px;
      `}

      &:before {
        content: '\f3cf';
      }
    }

    &.slick-next {
      border-left: 1px solid ${(props) => props.theme.cWarningDark};
      margin-right: -64px;

      ${rwd.Large`
        margin-right: -128px;
      `}

      &:before {
        content: '\f3d1';
      }
    }
  }

  .slick-dots {
    z-index: 1;
    height: auto;
    top: 100%;
    margin-left: 8px;
    text-align: center;
    width: 100%;
    margin-top: 28px;

    ${rwd.Medium`
      margin-top: 36px;
      width: 50%;
      padding-right: 32px;
      text-align: right;
      `}

    ${rwd.Large`
      margin-top: 52px;
      padding-right: 36px;
    `}

    li {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin: 0;

      button {
        display: block;
        padding: 8px;
        width: 24px;
        height: 24px;

        &:before {
          position: relative;
          content: '';
          display: block;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background-color: #e1e1e1;
          opacity: 1;
          //box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
          transition: background-color 0.2s;
        }

        &:hover {
          &:before {
            background-color: #0050ca;
          }
        }
      }

      &.slick-active {
        button {
          &:before {
            background-color: #ee220d;
          }
        }
      }
    }
  }

  .slick-track {
    display: flex;
    align-items: stretch;
  }

  .slick-slide {
    height: auto;

    & > div {
      height: 100%;
    }
  }
`

export const LearnMoreSlide = styled.div`
  outline: 0;
  height: 100%;
  padding-left: 32px;
  padding-right: 32px;

  ${rwd.Medium`
    padding-left: 32px;
    padding-right: 0;
  `}

  ${rwd.Large`
    padding-left: 64px;
    padding-right: 0;
  `}
`
