import styled from 'styled-components'
import rwd from 'styles/rwd'

export const Container = styled.div`
  position: relative;
  font-size: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  padding: 32px 5px;
  z-index: 10;

  text-align: left;
  width: 100%;

  ${rwd.Medium`
    align-items: start;
    padding: 64px 0;
    flex-direction: row;
  `}
`
