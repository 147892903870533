import styled, { css } from 'styled-components'

export const Container = styled.div`
  position: relative;
  height: 100%;
  border: 1px solid ${(props) => props.theme.cLightGrey};
  padding: 32px;
  background-color: ${(props) => props.theme.cWhite};
`

export const Label = styled.div`
  font-size: 11px;
  line-height: 24px;
  letter-spacing: 2.1px;
  text-transform: uppercase;
  margin-bottom: 20px;
`

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.6px;
  margin-bottom: 20px;
`

export const Text = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.6px;
  margin-bottom: 20px;
`

export const Progress = styled.div`
  margin-bottom: 20px;
`

export const Close = styled.i.attrs({
  className: 'ion ion-md-close',
})`
  z-index: 4;
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  text-align: center;
  cursor: pointer;
  color: ${(props) => props.theme.cWhite};
  background-color: ${(props) => props.theme.cPrimary};
  transition: background-color 0.2s, color 0.2s;

  &:before {
    transition: transform 0.2s;
    font-size: 14px;
    line-height: 14px !important;
    width: 14px;
    height: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -7px;
    margin-left: -7px;
  }

  &:hover {
    color: ${(props) => props.theme.cBlack};
    background-color: ${(props) => props.theme.cWhite};

    &:before {
      transform: rotate(-180deg);
    }
  }
`

export const Checkmark = styled.i.attrs({
  className: 'ion ion-md-checkmark',
})`
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: ${(props) => props.theme.cPrimary}; */
  background: ${(props) => props.theme.cGrey2};
  color: ${(props) => props.theme.cWhite};
  font-size: 22px;
  padding: 12px;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  transition: background-color 0.2s, color 0.2s;

  ${({ isRead, onlyInvestmentNotifications }) =>
    isRead &&
    css`
      color: ${(props) => props.theme.cWhite};
      background-color: #79ca65;

      &:hover {
        color: ${onlyInvestmentNotifications
          ? (props) => props.theme.cWhite
          : '#79ca65'};
        background-color: ${onlyInvestmentNotifications
          ? '#79ca65'
          : (props) => props.theme.cWhite};
      }
    `}

  ${({ onlyInvestmentNotifications }) =>
    !onlyInvestmentNotifications &&
    css`
      &:hover {
        color: #79ca65 !important;
        background-color: ${(props) => props.theme.cWhite} !important;
      }
    `}
`

export const NotificationUrl = styled.div`
  ${(props) =>
    props.withCTAOnCenter &&
    `
      display: flex;
      justify-content:center;
      padding-top:32px;
`}
  i {
    margin-right: 8px;
    color: #ee220d;
  }

  span {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-size: 11px;
    font-weight: bold;
    line-height: 1.82;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  &:hover {
    opacity: 0.6;
  }
`
