import { createGlobalStyle } from 'styled-components'
import 'semantic-ui-css/semantic.min.css'
import 'react-tippy/dist/tippy.css'

import fontFaces from 'styles/fonts'
import semanticUiStyle from 'styles/semanticUi'
import reactTippyStyle from 'styles/reactTippy'

const GlobalStyle = createGlobalStyle`
  ${fontFaces}

  * ,
  *:before ,
  *:after {
    box-sizing: border-box;
  }

  html ,
  body {
    margin: 0;
    font-family: 'Open Sans';
    font-size: 10px;
    line-height: 1;
    background-color: ${(props) => props.theme.cWhite};
  }

  input ,
  select ,
  textarea ,
  button {
    font-family: 'Open Sans';
  }

  button {
    background-color: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
  }

  select {
    appearance: none;
  }

  select::-ms-expand {
    display: none;
  }

  a {
    font-weight: normal;
    color: inherit;

    &:hover {
      text-decoration: none;
      color: inherit;
    }
  }

  ::selection {
    color: ${(props) => props.theme.cWhite};
    background-color: ${(props) => props.theme.cPrimary};
  }

  ${semanticUiStyle}
  ${reactTippyStyle}

  .collapse-test {
    position: relative;
  }
`

export default GlobalStyle
