import React from 'react'
import PropTypes from 'prop-types'

import {
  Container,
  Account,
  TransferIcon,
  Value,
  Name,
} from './TransactionInfo.styles'
import { formatCurrencyValue } from '@stokr/components-library/dist/utils/formatCurrencyValue'

const TransactionInfo = ({
  value1,
  unit1,
  name1,
  owner1,
  value2,
  unit2,
  name2,
  owner2,
  sent,
  received,
  tokenDecimals,
}) => (
  <Container>
    <Account>
      <Value>
        {unit1 && value1
          ? formatCurrencyValue(unit1, value1, tokenDecimals)
          : value1}
      </Value>
      {/*(you) comes from */}
      <Name
        style={{ fontSize: 'inherit', marginTop: 15 }}
      >{`${name1} ${owner1}`}</Name>
    </Account>
    <TransferIcon sent={sent} received={received} />
    <Account>
      <Value>
        {unit2 && value2
          ? formatCurrencyValue(unit2, value2, tokenDecimals)
          : value2}
      </Value>
      <Name
        style={{ fontSize: 'inherit', marginTop: 15 }}
      >{`${name2} ${owner2}`}</Name>
    </Account>
  </Container>
)

TransactionInfo.propTypes = {
  value1: PropTypes.number,
  unit1: PropTypes.string,
  name1: PropTypes.string,
  owner1: PropTypes.string,
  value2: PropTypes.number,
  unit2: PropTypes.string,
  name2: PropTypes.string,
  owner2: PropTypes.string,
}

TransactionInfo.defaultProps = {
  value1: '',
  unit1: '',
  name1: '',
  owner1: '',
  value2: '',
  unit2: '',
  name2: '',
  owner2: '',
}

export default TransactionInfo
