import React from 'react'
import PropTypes from 'prop-types'

import { Wrapper, Label, Value, Equal } from './ProfileStat.styles'

const lineBreaker = {
  wordBreak: 'break-all',
}

const ProfileBadge = ({ label, unit, value, eqUnit, eqValue, withBorder }) => (
  <Wrapper withBorder={withBorder}>
    <Label withBorder={withBorder}>{label}</Label>
    <Value style={lineBreaker}>
      {unit && <span>{unit}</span>}
      {value}
    </Value>

    {eqValue && eqUnit ? (
      <Equal>
        {'= '}
        {eqUnit && <span>{eqUnit}</span>}
        {eqValue}
      </Equal>
    ) : (
      ''
    )}
  </Wrapper>
)

ProfileBadge.propTypes = {
  label: PropTypes.string.isRequired,
  unit: PropTypes.string,
  value: PropTypes.string.isRequired,
  eqUnit: PropTypes.string,
  eqValue: PropTypes.string.isRequired,
}

ProfileBadge.defaultProps = {
  unit: '',
  eqUnit: '',
}

export default ProfileBadge
