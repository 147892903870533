import PropTypes from 'prop-types'
import React from 'react'

import {
  Container,
  Wrap,
  Items,
  Item,
  ProgressText,
} from './TransferProgress.styles'

const TransferProgress = ({ items, doneIndex, isRedemption, steps }) => {
  const [progressText, setProgressText] = React.useState(
    steps[doneIndex]?.label,
  )

  //current index is the index of hovering element
  const [currentIndex, setCurrentIndex] = React.useState(doneIndex)

  const getStepText = (step) => {
    return steps[step]?.label
  }

  return (
    <Container>
      <Wrap>
        <Items>
          {items.map((item, key) => (
            <Item
              key={item.id}
              isRedemption={isRedemption}
              isDone={key <= doneIndex}
              onClick={() => {
                //item.handleClick && item.handleClick(item.id)
              }}
              onMouseEnter={() => {
                setCurrentIndex(key)
                setProgressText(getStepText(key))
                
              }}
              onMouseLeave={() => {
                setCurrentIndex(doneIndex)
                setProgressText(getStepText(doneIndex))
              }}
            />
          ))}
        </Items>
      </Wrap>
      <ProgressText
        isDone={currentIndex <= doneIndex}
        isRedemption={isRedemption}
      >
        {progressText}
      </ProgressText>
    </Container>
  )
}

TransferProgress.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  doneIndex: PropTypes.number,
  activeIndex: PropTypes.number,
}

TransferProgress.defaultProps = {
  doneIndex: -1,
  activeIndex: -1,
}

export default TransferProgress
