import styled from 'styled-components'
import rwd from 'styles/rwd'

export const Container = styled.div`
  display: block;
  width: 100%;
  // height: 100%;
`

export const Wrap = styled.div`
  display: block;
  margin: 0 auto;
`

export const Items = styled.div`
  display: flex;
  height: 100%;
  font-size: 0;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  text-align: center;
`

export const ProgressText = styled.div`
  color: ${(props) => props.theme.cPrimary};
  //text-transform: uppercase;
  letter-spacing: 0.4px;
  font-size: 11px;
  line-height: 16px;
  height: 32px;
  opacity: 0.75
    ${(props) =>
      props.isDone &&
      `
      opacity:1;
    `};
  ${(props) => props.isRedemption && `color: ${props.theme.cWarning};`};
`

export const Item = styled.button`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  flex: 1;
  margin: 0 2px;
  height: 32px;
  cursor: pointer;

  &:before {
    z-index: 0;
    content: '';
    display: block;
    width: 100%;
    height: 6px;
    position: absolute;
    top: 14px;
    left: 0;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.05);
    background-color: ${(props) => props.theme.cPrimary};
    opacity: 0.2;
  }

  &:after {
    z-index: 1;
    content: '';
    display: block;
    width: 100%;
    height: 6px;
    position: absolute;
    top: 14px;
    left: 0;
    background-color: transparent;
    transition: background-color 0.2s;
  }

  &:hover:after {
    background-color: ${(props) => props.theme.cPrimary};
    opacity: 0.35;
  }

  ${(props) =>
    props.isDone &&
    `
    &:after {
      background-color: ${props.theme.cPrimary};
      opacity:1;

      ${
        props.isRedemption &&
        `
        background-color: ${props.theme.cWarning};
      `
      }
    }
    &:hover:after {
    opacity: 1;
  }

  
  `}

  ${(props) =>
    props.isRedemption &&
    `
    &:before, &:hover:after {
    background-color: ${props.theme.cWarning};
    }
  `}
  
  ${rwd.Medium`
    width: 32px;
  `}
`
