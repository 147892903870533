import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Collapse } from 'react-collapse'
import {
  BackButton,
  ComponentScroll,
  Row,
  TabsNav,
} from '@stokr/components-library'
import { getMedia } from 'styles/rwd'
import LearnMoreItem from './LearnMoreItem'
import {
  TabNav,
  TabArrow,
  Container,
  TitleContainer,
  SubTabNav,
  SidebarColumn,
  SidebarHeading,
  SidebarTitle,
  ContentColumn,
  MobileMenuToggler,
} from './LearnMore.styles'
import {
  CompleteMarkTooltip,
  CompleteMark,
  CompleteMarkContainer,
} from './LearnMore.shared.styles'
import { withRouter } from 'utils/withRouter'

class LearnMore extends PureComponent {
  state = {
    openedTab: '',
    isMobile: false,
    isSidebarExpanded: false,
  }

  componentDidMount() {
    this.setDefaultActiveTab()
    window.addEventListener('resize', this.onResize)
    this.onResizeTimeout()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
  }

  getActiveTab = (tabId) => {
    const { categories } = this.props
    let activeTabObject = {}

    categories.find((category) => {
      const { subcategories } = category
      if (subcategories.length) {
        activeTabObject = subcategories.find(
          (subcategory) => subcategory.id === tabId,
        )
        if (activeTabObject) {
          return true
        }
      }

      if (category.id === tabId) {
        activeTabObject = category
        return true
      }

      return false
    })

    return activeTabObject
  }

  setDefaultActiveTab = () => {
    const { activeCategory } = this.props

    this.toggleTab(activeCategory)
  }

  toggleTab = (tabId) => {
    const { openedTab } = this.state

    if (openedTab === tabId) {
      this.setState({
        openedTab: '',
      })
      return
    }

    this.setState({
      openedTab: tabId,
    })
  }

  toggleSidebar = () => {
    this.setState((prevState) => ({
      isSidebarExpanded: !prevState.isSidebarExpanded,
    }))
  }

  onResize = () => {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(this.onResizeTimeout, 200)
  }

  onResizeTimeout = () => {
    this.setState({
      isMobile: this.checkIsMobile(),
    })
  }

  checkIsMobile = () => !window.matchMedia(getMedia('Medium')).matches

  navigateToSubcategory = (subcategoryId) => {
    const { categories, items, navigate } = this.props
    navigate(`/learn-more/${subcategoryId}`, {
      state: {
        learnMore: {
          categories,
          items,
        },
      },
    })
  }

  render() {
    const { activeItem, items, categories, onMarkComplete } = this.props

    const { openedTab, isMobile, isSidebarExpanded } = this.state

    const activeTabObject = this.getActiveTab(activeItem) || {}
    const activeItemObject = items.find((post) => post.id === activeItem)

    return (
      <Container>
        <Row nowrap>
          {isMobile && (
            <MobileMenuToggler
              isActive={isSidebarExpanded}
              onClick={this.toggleSidebar}
            >
              <span />
              <span />
              <span />
            </MobileMenuToggler>
          )}
          <SidebarColumn part={6} isExpanded={isSidebarExpanded}>
            <ComponentScroll
              autoHeight
              autoHeightMin="100vh"
              autoHeightMax="100vh"
            >
              <TitleContainer>
                <SidebarHeading>INVESTING 101</SidebarHeading>
                <SidebarTitle>LEARN MORE ABOUT INVESTING</SidebarTitle>
                <BackButton to="/">Back to previous page</BackButton>
              </TitleContainer>
              <TabsNav activeTab={activeItem}>
                {categories.map((category) => (
                  <Fragment key={category.id}>
                    <TabNav
                      borderTop
                      tabId={category.id}
                      isActive={category.subcategories.some(
                        (subcategory) => subcategory.id === activeItem,
                      )}
                      complete={category.complete}
                      onClick={() => this.toggleTab(category.id)}
                    >
                      <TabArrow isActive={openedTab === category.id} />
                      {category.title}
                      <CompleteMarkTooltip
                        disabled={!category.tooltip}
                        position="right center"
                        content={category.tooltip}
                        trigger={
                          <CompleteMarkContainer
                            inProgress={category.inProgress}
                            complete={category.complete}
                          >
                            <CompleteMark />
                          </CompleteMarkContainer>
                        }
                      />
                    </TabNav>
                    <Collapse isOpened={openedTab === category.id}>
                      {category.subcategories.map((subcategory) => (
                        <SubTabNav
                          key={subcategory.id}
                          tabId={subcategory.id}
                          isActive={activeItem === subcategory.id}
                          onClick={() =>
                            this.navigateToSubcategory(subcategory.id)
                          }
                        >
                          {subcategory.title}
                          <CompleteMarkTooltip
                            disabled={!subcategory.tooltip}
                            position="right center"
                            content={subcategory.tooltip}
                            trigger={
                              <CompleteMarkContainer
                                inProgress={subcategory.inProgress}
                                complete={subcategory.complete}
                              >
                                <CompleteMark />
                              </CompleteMarkContainer>
                            }
                          />
                        </SubTabNav>
                      ))}
                    </Collapse>
                  </Fragment>
                ))}
              </TabsNav>
            </ComponentScroll>
          </SidebarColumn>
          <ContentColumn part={16}>
            {activeItemObject && (
              <LearnMoreItem
                postItem={activeItemObject}
                activeTabObject={activeTabObject}
                onMarkComplete={onMarkComplete}
              />
            )}
          </ContentColumn>
        </Row>
      </Container>
    )
  }
}

export const learnMoreCategoryPropTypes = {
  title: PropTypes.string.isRequired,
  inProgress: PropTypes.bool,
  complete: PropTypes.bool,
  tooltip: PropTypes.node,
}

export const learnMorePostPropTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  postType: PropTypes.oneOf(['video', 'text']),
  cover: PropTypes.string,
  videoSrc: PropTypes.string,
}

LearnMore.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(learnMorePostPropTypes)).isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      ...learnMoreCategoryPropTypes,
      subcategories: PropTypes.arrayOf(
        PropTypes.shape(learnMoreCategoryPropTypes),
      ),
    }),
  ).isRequired,
  onMarkComplete: PropTypes.func,
  activeCategory: PropTypes.string.isRequired,
  activeItem: PropTypes.string.isRequired,
}

LearnMore.defaultProps = {
  onMarkComplete: () => {},
}

export default withRouter(LearnMore)
