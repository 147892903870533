import React from 'react'
import PropTypes from 'prop-types'
import Slick from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { Container, Offset, Wrapper } from './LearnMoreCarousel.styles'

const LearnMoreCarousel = ({ children, autoplay, dots, arrows, isMobile }) => {
  const settings = {
    dots,
    arrows,
    infinite: false,
    speed: 600,
    autoplay: !!autoplay,
    autoplaySpeed: autoplay,
    slidesToShow: isMobile ? 1 : 2,
    slidesToScroll: isMobile ? 1 : 1,
  }

  return (
    <Container>
      <Offset>
        <Wrapper>
          <Slick {...settings}>{children}</Slick>
        </Wrapper>
      </Offset>
    </Container>
  )
}

LearnMoreCarousel.propTypes = {
  children: PropTypes.node.isRequired,
  autoplay: PropTypes.number,
  dots: PropTypes.bool,
  arrows: PropTypes.bool,
  isMobile: PropTypes.bool,
}

LearnMoreCarousel.defaultProps = {
  autoplay: 0,
  dots: false,
  arrows: false,
  isMobile: false,
}

export default LearnMoreCarousel
