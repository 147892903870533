import React from 'react'
import PropTypes from 'prop-types'
import { ComponentWrapper } from '@stokr/components-library'

import { Button, Wrapper, Title, Arrow, Counter } from './ExpandButton.styles'

const ExpandButton = ({
  title,
  counter,
  isOpened,
  onClick,
  textLeft,
  greenCounter,
}) => (
  <Button onClick={onClick} isOpened={isOpened}>
    <ComponentWrapper>
      <Wrapper textLeft={textLeft}>
        <Title>{title}</Title>
        {counter > 0 && (
          <Counter greenCounter={greenCounter}>{counter}</Counter>
        )}
        <Arrow isOpened={isOpened} />
      </Wrapper>
    </ComponentWrapper>
  </Button>
)

ExpandButton.propTypes = {
  title: PropTypes.string.isRequired,
  counter: PropTypes.number,
  isOpened: PropTypes.bool,
  onClick: PropTypes.func,
  textLeft: PropTypes.bool,
}

ExpandButton.defaultProps = {
  isOpened: false,
  textLeft: false,
  counter: 0,
  onClick: () => {},
}

export default ExpandButton
