import React, { Fragment, useContext, useEffect, useState } from 'react'
import {
  ComponentWrapper,
  Modal,
  ModalInner,
  StepControllerProvider,
  StepControllerConsumer,
  ModalBack,
  StepController,
  Text,
} from '@stokr/components-library'
import { EventDBContext } from 'context/EventDBContext/EventDBContext'
import fetchData from 'api/fetch-data'
import RequestFormStep from './RequestFormStep'
import BackupFormStep from './BackupFormStep'
import { fixDecimals } from '@stokr/components-library/dist/utils/fix-decimals'

const useRedemptionForm = (
  user,
  redeemableAssets,
  redemptionTransactions,
  onSuccess,
  checkboxes,
) => {
  const { storeInvestment, tokenBalances } = useContext(EventDBContext)
  const [isUploading, setIsUploading] = useState(false)
  const [error, setError] = useState(false)
  const [userWalletWithBalances, setuserWalletWithBalances] = useState([])
  const [isWalletCollapseOpened, setisWalletCollapseOpened] = useState(false)
  const [selectedWallet, setselectedWallet] = useState()
  const [formData, setformData] = useState()

  useEffect(() => {
    if (tokenBalances && user?.wallets) {
      let walletWithBalance = user.wallets
        .filter((wallet) => !wallet.assetIds)
        .map((wallet) => {
          const walletBalances = tokenBalances
            .filter((x) => x.account === wallet.address)
            .map((x) => ({
              asset: x.tokenSymbol,
              balance: x.balance,
              originalBalance: x.balance,
              tokenPrice: x.tokenPrice,
              requestedAmount: 0,
              tokenDecimals: x.tokenDecimals,
            }))

          // calculate remaining balance for each wallet address and asset
          //based on requested amount from transactions
          const walletTransactions = redemptionTransactions.filter(
            (tx) => tx.GAID === wallet.address && tx.fulfilled !== true,
          )

          walletTransactions.forEach((tx) => {
            const matchingBalance = walletBalances.find(
              (balance) => balance.asset === tx.tokenSymbol,
            )
            if (matchingBalance) {
              matchingBalance.balance = (
                matchingBalance.balance - parseFloat(tx.tokenAmount)
              ).toFixed(matchingBalance.tokenDecimals)

              matchingBalance.requestedAmount = (
                matchingBalance.balance - parseFloat(tx.tokenAmount)
              ).toFixed(matchingBalance.tokenDecimals)
            }
          })

          return {
            ...wallet,
            balances: walletBalances,
          }
        })
      setuserWalletWithBalances(walletWithBalance)
    }
  }, [user, tokenBalances, redemptionTransactions])

  const handleSubmit = async (values) => {
    setIsUploading(true)
    const redeemAsset = redeemableAssets.find(
      (x) => x.project.tokenSymbol === values.asset,
    )
    const { project, conversionPrice } = redeemAsset

    const dataToSend = {
      project: project._id,
      tokenAmount: values.amount,
      tokenPrice: conversionPrice,
      currencyType: values.currency,
      currencyAmount: fixDecimals(
        parseFloat(conversionPrice) * values.amount,
        8,
      ), //for LBTC currency we have 8 decimals
      tokenName: project.tokenName,
      tokenSymbol: project.tokenSymbol,
      GAID: values.wallet,
      tokenUID: project.secondaryAssetId,
      isRedemption: true,
      checkedCheckboxes: Object.keys(checkboxes),
      skipDocGeneration: true,
    }

    try {
      const { investment } = await storeInvestment(dataToSend)
      setIsUploading(false)
      setError(false)
      setselectedWallet()
      setformData()
      onSuccess && onSuccess(investment)
    } catch (error) {
      console.error(error)
      setIsUploading(false)
      setError(true)
    }
  }

  return {
    handleSubmit,
    isUploading,
    error,
    userWalletWithBalances,
    isWalletCollapseOpened,
    setisWalletCollapseOpened,
    selectedWallet,
    setselectedWallet,
    setError,
    formData,
    setformData,
  }
}

const stepsNames = ['request', 'backup']

export const RedemptionRequestModal = ({
  user,
  redeemableAssets,
  onSuccess,
  transactions,
  ...props
}) => {
  const [redemptionCheckboxes, setredemptionCheckboxes] = useState({
    confirmInformation: {
      agreementText:
        '<p>I confirm that the information above is correct<br></p>',
      group: 'redemptionRequest',
      label: 'confirmInformation',
      text: '',
      title: '',
    },
  })

  const redemptionForm = useRedemptionForm(
    user,
    redeemableAssets,
    transactions,
    onSuccess,
    redemptionCheckboxes,
  )

  const {
    error,
    setError,
    setselectedWallet,
    setformData,
    setisWalletCollapseOpened,
  } = redemptionForm

  useEffect(() => {
    fetchCheckbox()
  }, [])

  const fetchCheckbox = async () => {
    try {
      const response = await fetchData('compliance/get-checkbox-group', {
        group: 'redemptionRequest',
      })
      if (response) {
        setredemptionCheckboxes({
          ...response,
        })
      }
    } catch (error) {
      console.log('🚀 ~ file: RegisterModal.js:75 ~ error:', error)
    }
  }

  const closeAndClearFile = () => {
    props.onModalClose()
    setError(false)
    setselectedWallet()
    setformData()
    setisWalletCollapseOpened(false)
  }

  return (
    <Modal isOpen={props.isModalOpen} onClose={closeAndClearFile}>
      <ModalInner noPadding>
        {error ? (
          <ErrorShow />
        ) : (
          <StepControllerProvider>
            <StepControllerConsumer>
              {(stepController) => {
                const prevStepIndex = stepController.activeStepIndex - 1

                return (
                  <>
                    <ModalBack
                      onClick={() => {
                        stepController.changeStep(
                          stepsNames[prevStepIndex],
                          prevStepIndex,
                        )
                      }}
                      hide={stepController.activeStepIndex === 0}
                    />
                    <StepController
                      stepsProgressList={stepsNames.map((step, index) => {
                        return {
                          id: step,
                          handleClick: () => {
                            stepController.changeStep(step, index)
                          },
                        }
                      })}
                      {...stepController}
                    >
                      <RequestFormStep
                        id="request"
                        changeStep={() =>
                          stepController.changeStep('backup', 1, 0)
                        }
                        redeemableAssets={redeemableAssets}
                        redemptionCheckboxes={redemptionCheckboxes}
                        redemptionForm={redemptionForm}
                      />
                      <BackupFormStep
                        id="backup"
                        redemptionForm={redemptionForm}
                        redemptionCheckboxes={redemptionCheckboxes}
                        // changeStep={() =>
                        //   stepController.changeStep('backup', 1, 0)
                        // }
                      />
                    </StepController>
                  </>
                )
              }}
            </StepControllerConsumer>
          </StepControllerProvider>
        )}
      </ModalInner>
    </Modal>
  )
}

const ErrorShow = () => (
  <>
    <ComponentWrapper noPaddingBottom>
      <Text>
        <h3>Request a Redemption</h3>
      </Text>
    </ComponentWrapper>
    <ComponentWrapper>
      <Text>
        <p>
          Something went wrong. Please contact{' '}
          <a href="mailto:support@stokr.io" style={{ color: 'inherit' }}>
            support@stokr.io
          </a>
        </p>
      </Text>
    </ComponentWrapper>
  </>
)

export default RedemptionRequestModal
