/* Libraries */
import Cookies from 'js-cookie'
import axios from '../model/axios'

const user = async (url, data) =>
  new Promise(async (resolve, reject) => {
    const accessToken = await Cookies.get('STOKR_ACCESS_TOKEN')

    if (!accessToken) {
      console.log('no access token')
    }

    try {
      const result = await axios({
        method: 'POST',
        url: `user/${url}`,
        data,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })

      // console.log(result);

      resolve(result.data)
    } catch (error) {
      console.log(`Error: ${error}`)

      reject(error)
    }
  })

export default user
