import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {
  Container as BackgroundContainer,
  Content as BackgroundContent,
} from '@stokr/components-library'
import rwd from 'styles/rwd'

export const Container = styled.div`
  height: 100%;
`

export const Cover = styled.div`
  width: 100%;
  height: 200px;

  ${rwd.Medium`
    height:auto;
  `}

  ${BackgroundContainer} {
    width: 100%;
    padding-top: 56.25%;
  }
`

export const Play = styled(Link)`
  width: 48px;
  height: 48px;
  color: ${(props) => props.theme.cWhite};
  background: ${(props) => props.theme.cWarning};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s background;

  &:hover {
    color: ${(props) => props.theme.cWhite};
    background: ${(props) => props.theme.cWarningDark};
  }
`

export const PlayIcon = styled.i.attrs({
  className: 'ion ion-md-play',
})`
  font-size: 16px;
`

export const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
`

export const Content = styled.div`
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.6px;
`
