import styled from 'styled-components'

const NotificationCounter = styled.span`
  display: inline-block;
  vertical-align: middle;
  color: ${(props) => props.theme.cWhite};
  background-color: ${(props) => props.theme.cWarning};
  border-radius: 8px;
  min-width: 16px;
  height: 16px;
  font-weight: 700;
  font-size: 11px !important;
  line-height: 11px;
  padding: 2px;
  text-align: center;
  letter-spacing: inherit !important;

  ${(props) =>
    props.avatar &&
    `
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  `}

  ${({ MobileMenuToggler, isActive }) =>
    MobileMenuToggler &&
    `
    top: 13px;
    right: 13px;


    `}
  ${({ isActive }) =>
    isActive &&
    `
      @media screen and (max-width: 767px) {
        display: none;
      }
    `}

    ${({ mobileMenu }) =>
    mobileMenu &&
    `
        position: relative;
        right: -10px;
      `}
`

export default NotificationCounter
