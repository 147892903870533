import styled, { css } from 'styled-components'
import { ComponentWrapper } from '@stokr/components-library'

import { TabsWrapper, StyledTabs } from 'components/TabsNav/TabsNav.styles'

export const Container = styled.div`
  width: 100%;
  overflow: hidden;
  z-index: 20;

  ${TabsWrapper} {
    padding: 0 !important;
  }

  ${StyledTabs} {
    border-bottom: none;
  }
`

export const TabNav = styled(ComponentWrapper)`
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;

  height: 104px;
  letter-spacing: 0.6px;
  line-height: 1.5;
  padding: 44px 64px !important;

  ${(props) =>
    props.isActive &&
    css`
      border-right: none !important;

      &::before {
        content: '';
        width: 8px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background: ${props.theme.cWarning};
      }
    `}
`

export const TabArrow = styled.i.attrs({
  className: 'ion ion-ios-arrow-forward',
})`
  color: ${(props) => props.theme.cWarning};
  margin-right: 15px;
`

export const SliderWrapper = styled.div`
  width: 100%;

  .slick-arrow {
    width: 64px;
    height: 48px;
    background: ${(props) => props.theme.cWarning};
    z-index: 10;
    transition: 0.3s ease background;

    &:hover,
    &:focus {
      background: ${(props) => props.theme.cWarningDark};

      &::before {
        color: ${(props) => props.theme.cWhite} !important;
      }
    }
  }

  .slick-arrow.slick-prev {
    top: auto;
    left: auto;
    bottom: 0;
    right: 64px;
    transform: translate(0);
  }

  .slick-arrow.slick-next {
    top: auto;
    left: auto;
    bottom: 0;
    right: 0;
    transform: translate(0);
    border-left: 1px solid ${(props) => props.theme.cWarningDark};
  }

  .slick-dots {
    width: auto;
    right: 164px;
    height: 36px;
    bottom: 0;
  }
`

export const TitleContainer = styled(ComponentWrapper)`
  height: 100%;
  display: flex;
  align-items: center;
  flex-grow: 1;

  ${(props) =>
    props.alignTop &&
    `
  align-items: flex-start;
  height:auto;
  flex-grow:inherit;
  `}
`

export const TabTitle = styled.div`
  padding: 42px 36px;
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 0.6px;
`

export const Arrow = styled.i.attrs({
  className: 'ion ion-ios-arrow-down',
})`
  &&& {
    position: absolute;
    top: 50%;
    right: 32px;
    transition: 0.3s transform;
    transform: rotate(0);
    text-align: center;
    font-size: 16px;
    line-height: 16px;
    height: 16px;
    margin-top: -8px;

    ${(props) =>
      props.isOpened &&
      `
      transform: rotate(180deg);
    `}
  }
`

export const SidebarSubHeading = styled.div`
  font-size: 11px;
  line-height: 24px;
  letter-spacing: 2.1px;
  text-transform: uppercase;
  margin-bottom: 20px;
`
