import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  Title,
  Checkmark,
  NotificationUrl,
  Text,
} from './NotificationItem.styles'
import UserChannelModal from './UserChannelModal'
import {
  Icon,
  iconsMap,
} from '@stokr/components-library/dist/components/Icon/Icon.style'

class UserChannel extends PureComponent {
  state = {
    isModalOpen: false,
  }

  toggleModal = (isModalOpen) => {
    this.setState({
      isModalOpen,
    })
  }

  render() {
    const { isModalOpen } = this.state

    return (
      <Container>
        <Checkmark isRead={true} onlyInvestmentNotifications={true} />
        <Title>{'How did you discover STOKR?'}</Title>
        <Text>
          {
            'Please help us improving our product by letting us know from where did you hear about us'
          }
        </Text>
        <NotificationUrl style={{ marginTop: '4.25rem' }}>
          <a
            onClick={() => this.toggleModal(true)}
            style={{ cursor: 'pointer' }}
          >
            <span>
              <Icon icon={iconsMap.arrowRight} />
              {'Select Channel'}
            </span>
          </a>
        </NotificationUrl>
        <UserChannelModal
          isModalOpen={isModalOpen}
          onModalClose={() => this.toggleModal(false)}
        />
      </Container>
    )
  }
}

UserChannel.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onFormSend: PropTypes.func.isRequired,
  onModalSwitch: PropTypes.func.isRequired,
}

export default UserChannel
