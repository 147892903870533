import NotFoundPage from 'pages/404'
import DashboardRoutes from './Dashboard'

const routes = [
  ...DashboardRoutes,
  {
    path: '*',
    element: NotFoundPage,
  },
]

export default routes
