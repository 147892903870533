import fetchData from 'api/fetch-data'
import fetchDataPublic from 'api/fetch-data-public'
import investment from 'api/investment'

export const fetchTokenBalances = async () =>
  await fetchData('wallets/tokenBalances')

export const fetchTokenTransfers = async () =>
  await fetchData('wallets/tokenTransfers')

export const fetchExchangeRates = (ethAddresses, liquidGaids) =>
  fetchData('investment/exchangerates')

export const fetchPriceChange = async () =>
  await fetchData('prices/getSideswapPriceChange')

export const fetchAQFPriceChange = async (data) =>
  await fetchData('prices/getNAVPriceChange', data)

export const fetchBTCPrice = async () => await fetchData('investment/btcprice')

export const fetchInvestment = async (url, data) => investment(url, data)

export const storeInvestment = async (data) =>
  fetchData('investment/create', data)

export const fetchProjects = async () =>
  await fetchDataPublic('project/public/get')

export const fetchProjectDocuments = async (data) =>
  await fetchData('documents/list', data)
