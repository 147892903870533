import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Background, Button, ComponentWrapper } from '@stokr/components-library'
import {
  ArticleWrapper,
  VideoCover,
  VideoPlay,
  VideoPlayIcon,
  VideoTitleRow,
  TitleText,
  CompleteWrapper,
  ContentText,
} from './LearnMoreItem.styles'

import { CompleteMarkContainer, CompleteMark } from './LearnMore.shared.styles'

const LearnMoreItem = ({ postItem, activeTabObject, onMarkComplete }) => (
  <ArticleWrapper postType={postItem.postType}>
    {postItem.postType === 'video' && (
      <Fragment>
        <VideoCover>
          <Background src={postItem.cover}>
            <VideoPlay>
              <VideoPlayIcon />
            </VideoPlay>
          </Background>
        </VideoCover>
        <ComponentWrapper noPaddingRight noPaddingBottom>
          <VideoTitleRow>
            <TitleText postType={postItem.postType}>
              <h3>{postItem.title}</h3>
            </TitleText>
            <CompleteWrapper>
              <Button
                primary={!activeTabObject.complete}
                secondary={activeTabObject.complete}
                onClick={() => onMarkComplete(activeTabObject.id)}
              >
                {activeTabObject.complete ? 'Watched' : 'Mark as watched'}
              </Button>
              <CompleteMarkContainer
                large
                inProgress={activeTabObject.inProgress}
                complete={activeTabObject.complete}
              >
                <CompleteMark />
              </CompleteMarkContainer>
            </CompleteWrapper>
          </VideoTitleRow>
        </ComponentWrapper>
        <ComponentWrapper>
          <ContentText dangerouslySetInnerHTML={{ __html: postItem.content }} />
        </ComponentWrapper>
      </Fragment>
    )}
    {postItem.postType === 'text' && (
      <Fragment>
        <ComponentWrapper noPaddingRight noPaddingBottom>
          <TitleText postType={postItem.postType}>
            <h3>{postItem.title}</h3>
          </TitleText>
        </ComponentWrapper>
        <ComponentWrapper>
          <ContentText dangerouslySetInnerHTML={{ __html: postItem.content }} />
        </ComponentWrapper>
        <ComponentWrapper>
          <CompleteWrapper center>
            <Button
              primary={!activeTabObject.complete}
              secondary={activeTabObject.complete}
              onClick={() => onMarkComplete(activeTabObject.id)}
            >
              {activeTabObject.complete ? 'Already Read' : 'Mark as read'}
            </Button>
            <CompleteMarkContainer
              large
              inProgress={activeTabObject.inProgress}
              complete={activeTabObject.complete}
            >
              <CompleteMark />
            </CompleteMarkContainer>
          </CompleteWrapper>
        </ComponentWrapper>
      </Fragment>
    )}
  </ArticleWrapper>
)
const learnMoreCategoryPropTypes = {
  title: PropTypes.string.isRequired,
  inProgress: PropTypes.bool,
  complete: PropTypes.bool,
  tooltip: PropTypes.node,
}
const learnMorePostPropTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  postType: PropTypes.oneOf(['video', 'text']),
  cover: PropTypes.string,
  videoSrc: PropTypes.string,
}

LearnMoreItem.propTypes = {
  postItem: PropTypes.shape(learnMorePostPropTypes).isRequired,
  activeTabObject: PropTypes.shape(learnMoreCategoryPropTypes).isRequired,
  onMarkComplete: PropTypes.func.isRequired,
}

export default LearnMoreItem
