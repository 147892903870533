import styled from 'styled-components'
import rwd from 'styles/rwd'

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
`

export const Account = styled.div`
  flex-basis: 40%;
  display: flex;
  flex-direction: column;
  // flex-basis: auto;

  &:last-of-type {
    text-align: right;
  }
`

export const TransferIcon = styled.div.attrs({
  className: 'ion',
})`
  width: 16px;
  height: 19px;
  position: relative;
  margin: 5px auto;

  &::before,
  &::after {
    font-size: 28px;
    position: absolute;
    left: 0;
  }

  ${(props) =>
    props.received &&
    `
    &::before {
      content: '\f117';
      color: ${props.theme.cSuccess};
      top: -7px;
    }
  `}

  ${(props) =>
    props.sent &&
    `
    &::after {
      content: '\f119';
      color: ${props.theme.cWarning};
      top: 2px;
    }
  `}

  ${(props) =>
    !props.sent &&
    !props.received &&
    `
    &::before {
      content: '\f117';
      color: ${props.theme.cSuccess};
      top: -7px;
    }
    &::after {
      content: '\f119';
      color: ${props.theme.cWarning};
      top: 2px;
    }
 
  `}


  @media (max-width: 550px) {
    margin: 18px auto;
  }
`

export const Value = styled.div`
  font-size: 22px;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 0.8px;

  @media (max-width: 550px) {
    min-height: 55px;
    display: flex;
    align-items: center;
  }
`

export const Name = styled.div`
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.4px;
  font-style: italic;
  margin-bottom: 3px;
`
