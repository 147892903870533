import { ProjectStates, ProjectStatus, ProjectTypes } from '../constants/enums'

export const generateProjectState = (project) => {
  const { status, isOpen, hasClosed } = project

  //"on hold" status is the same for all projects
  if (status === ProjectStatus.ON_HOLD) {
    //on hold is considered as closed state
    return ProjectStates.CLOSED
  } else {
    if (isOpen) {
      return ProjectStates.LIVE
    } else if (hasClosed) {
      // const investmentTargetReached = project.totalSold >= project.totalSoftCap
      // return investmentTargetReached
      //   ? ProjectStates.CLOSED_SUCCESSFULLY
      //   : ProjectStates.CLOSED

      return ProjectStates.CLOSED
    } else return ProjectStates.UPCOMING
  }
}

export const getTrancheState = (project) => {
  const currentTranche =
    project.sales && project.sales.find((x) => x.isVariable)
  const today = Date.now()

  if (currentTranche) {
    if (
      !currentTranche.startDate ||
      today < new Date(currentTranche.startDate)
    ) {
      return ProjectStates.UPCOMING
    } else if (
      !currentTranche.endDate ||
      today < new Date(currentTranche.endDate)
    ) {
      return ProjectStates.LIVE
    } else if (today > new Date(currentTranche.endDate)) {
      return ProjectStates.CLOSED
    } else {
      return ProjectStates.UPCOMING
    }
  } else {
    return ProjectStates.UPCOMING
  }
}
