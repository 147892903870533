export const saveAs = (file, fileName = 'fileName') => {
  // Create blob link to download
  const url = window.URL.createObjectURL(file)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)

  // Append to html link element page
  document.body.appendChild(link)

  // Start download
  link.click()

  // Clean up and remove the link
  link.parentNode.removeChild(link)
}
export const openFile = (file) => {
  var url = window.URL.createObjectURL(file)

  window.open(url)
}
