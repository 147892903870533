import React from 'react'
import { getMedia } from 'styles/rwd'

export const useMobileView = (props) => {
  const [isMobile, setisMobile] = React.useState(false)

  const timeout = React.useRef()

  React.useEffect(() => {
    window.addEventListener('resize', onResize)
    onResizeTimeout()

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  const onResize = () => {
    clearTimeout(timeout.current)
    timeout.current = setTimeout(onResizeTimeout, 200)
  }
  const onResizeTimeout = () => {
    setisMobile(checkIsMobile())
  }
  const checkIsMobile = () => !window.matchMedia(getMedia('Medium')).matches

  return isMobile
}
