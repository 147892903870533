import styled from 'styled-components'

export const Chart = styled.div`
  position: relative;
  display: block;
  max-width: 320px;

  & > svg {
    width: 100%;
    height: auto;
    width: 320px;
    height: 320px;
  }

  ${(props) =>
    props.center &&
    `
    margin: 0 auto;
  `}
`

const thickness = '14px'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: ${thickness};
  left: ${thickness};
  right: ${thickness};
  bottom: ${thickness};
  text-align: center;
`

export const DonutChartLabel = styled.p`
  font-family: 'Open Sans';
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 4px 0;
  margin: 0;

  ${(props) =>
    props.strong &&
    `
    font-weight: bold;
  `}
`

export const DonutChartValue = styled.h3`
  font-family: 'Open Sans';
  font-weight: 300;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '55px')};
  line-height: 58px;
  letter-spacing: 2.4px;
  margin: 0;
  padding: 7px 0;
  margin-bottom: 7px;
`

export const DonutChartInfo = styled.p`
  font-family: 'Open Sans';
  font-weight: 300;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.8px;
  margin: 0;
`

export const DonutChartTrend = styled.span`
  display: inline-block;
  margin-left: 8px;

  &:before {
    content: '\\f1fc';
    font-family: 'Ionicons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
    line-height: 12px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -1px;
    width: 12px;
    height: 12px;
    margin-right: 8px;
  }

  ${(props) =>
    props.inc &&
    `
    color: ${props.theme.cProgressDone};

    &:before {
      content: '\\f3d8';
    }
  `}

  ${(props) =>
    props.dec &&
    `
    color: ${props.theme.cWarning};

    &:before {
      content: '\\f3d0';
    }
  `}
`
