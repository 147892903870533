import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import BasicTable from 'components/BasicTable/BasicTable'
import { Container, Toggle, DetailsCollapse } from './TransactionDetails.styles'

class TransactionDetails extends PureComponent {
  state = {
    showDetails: false,
    files: null,
  }

  toggleDetails = () => {
    const { showDetails } = this.state

    this.setState({
      showDetails: !showDetails,
    })
  }

  render() {
    const { showDetails } = this.state
    const { data, files, investmentId } = this.props

    return (
      <Container>
        <Toggle onClick={this.toggleDetails}>
          {showDetails ? 'close details' : 'view details'}
        </Toggle>
        <DetailsCollapse isOpened={showDetails}>
          <BasicTable data={data} investmentId={investmentId} />
        </DetailsCollapse>
      </Container>
    )
  }
}

TransactionDetails.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default TransactionDetails
