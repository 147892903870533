import React, { useEffect, useState, useContext } from 'react'
import {
  Column,
  Row,
  ComponentWrapper,
  Tabs,
  Text,
  Tab,
} from '@stokr/components-library'
import { EventDBContext } from 'context/EventDBContext/EventDBContext'
import moment from 'moment'
import { useMobileView } from 'utils/useMobileView'
import { Collapse } from 'react-collapse'
import TabsNav from 'components/TabsNav/TabsNav'

import {
  TabNav,
  TabArrow,
  Container,
  TitleContainer,
  TabTitle,
  Arrow,
  SidebarSubHeading,
} from 'components/LearnMoreSection/LearnMore.styles'
import BasicTable from 'components/BasicTable/BasicTable'
import {
  Label,
  Value,
  Row as TableRow,
} from 'components/BasicTable/BasicTable.styles'

const Documents = () => {
  const { projectInvestedIn, investmentDocuments, isLoadingDocuments } =
    useContext(EventDBContext)

  const isMobile = useMobileView()
  const [activeTab, setactiveTab] = useState(projectInvestedIn[0]?._id)

  useEffect(() => {
    if (projectInvestedIn && !isLoadingDocuments) {
      setactiveTab(projectInvestedIn[0]?._id)
    }
  }, [isLoadingDocuments, projectInvestedIn])

  const onTabChange = (tabId) => {
    setactiveTab(tabId)
  }

  projectInvestedIn.sort((a, b) => {
    return Number(a.trending_order) - Number(b.trending_order)
  })

  return isMobile ? (
    <Container>
      <Row>
        <Column>
          <TitleContainer>
            <Text>
              {/* <p>INVESTING 101</p> */}
              <SidebarSubHeading>PROJECT DOCUMENTS</SidebarSubHeading>
              <h3>DOCUMENTS</h3>
            </Text>
          </TitleContainer>
          {projectInvestedIn.map((project, index) => {
            const projectDocs = getProjectDocuments(
              investmentDocuments,
              project,
            )

            return (
              <ComponentWrapper noPadding borderTop key={project._id + index}>
                <TabTitle onClick={() => onTabChange(project._id)}>
                  {project.title}
                  <Arrow isOpened={activeTab === project._id} />
                </TabTitle>
                <Collapse isOpened={activeTab === project._id}>
                  <ComponentWrapper>
                    {projectDocs?.length > 0 ? (
                      <>
                        <TableRow>
                          <Label>Document type</Label>

                          <Value width={'35%'}>Date </Value>
                        </TableRow>
                        <BasicTable data={projectDocs} />
                      </>
                    ) : (
                      <Text>
                        <h4>No documents available</h4>
                      </Text>
                    )}
                  </ComponentWrapper>
                </Collapse>
              </ComponentWrapper>
            )
          })}
        </Column>
      </Row>
    </Container>
  ) : (
    <Container>
      <Row>
        <Column part={5} style={{ height: 'auto' }}>
          <TitleContainer borderRight borderBottom alignTop>
            <Text responsiveFont>
              <SidebarSubHeading>PROJECT DOCUMENTS</SidebarSubHeading>
              <h3>DOCUMENTS</h3>
            </Text>
          </TitleContainer>

          <TabsNav activeTab={activeTab}>
            {projectInvestedIn.map((project) => (
              <TabNav
                key={project._id}
                borderBottom
                borderRight
                tabId={project._id}
                isActive={activeTab === project._id}
                onClick={() => onTabChange(project._id)}
              >
                <TabArrow />
                {project.title}
              </TabNav>
            ))}
          </TabsNav>
          <TitleContainer borderRight>
            <Text></Text>
          </TitleContainer>
        </Column>
        <Column
          part={11}
          style={{
            justifyContent: `${isLoadingDocuments ? 'center' : 'initial'}`,
          }}
        >
          {isLoadingDocuments ? (
            <ComponentWrapper center>
              <Text blockchainLoadingMsg>
                <h4>A few seconds left. We are still reading the data…</h4>
              </Text>
            </ComponentWrapper>
          ) : (
            <Tabs activeTab={activeTab}>
              {projectInvestedIn.map((project) => {
                const projectDocs = getProjectDocuments(
                  investmentDocuments,
                  project,
                )

                return (
                  <Tab tabId={project._id} key={project._id}>
                    <ComponentWrapper>
                      {projectDocs?.length > 0 ? (
                        <>
                          <TableRow>
                            <Label>Document type</Label>

                            <Value width={'35%'}>Date </Value>
                          </TableRow>
                          <BasicTable data={projectDocs} />
                        </>
                      ) : (
                        <Text>
                          <h4>No documents available</h4>
                        </Text>
                      )}
                    </ComponentWrapper>
                  </Tab>
                )
              })}
            </Tabs>
          )}
        </Column>
      </Row>
    </Container>
  )
}

const getProjectDocuments = (documents, project) => {
  const projectDocs = documents?.filter((x) => x.projectId === project._id)

  projectDocs
    .sort((a, b) => {
      if (a.onlyInvestors) return -1
      return new Date(b.investmentCreatedAt) - new Date(a.investmentCreatedAt)
    })
    .forEach((doc) => {
      doc.label = doc.fileName
      doc.isDocument = true
      doc.value = doc.onlyInvestors
        ? ''
        : moment(doc.investmentCreatedAt).format('DD MMM YYYY, hh:mm:ss')
    })
  return projectDocs
}

export default Documents
