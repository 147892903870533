import DashboardPage from 'pages/dashboard'
import EthereumAddress from 'pages/settings/ethereum-address'
import AlgorandAddress from 'pages/settings/algorand-address'

import LiquidAddress from 'pages/settings/liquid-address'
import SettingsProfileAndPrivacy from 'pages/settings/profile-and-privacy'
import { LoginWithOTPFlow } from '@stokr/components-library'
import OopsPage from 'pages/oops'
import ChartBox from 'components/ChartBox/ChartBox'
import Transactions from 'components/Transactions/Transactions'
import Redemptions from 'components/ChartBox/Redemptions'
import Documents from 'components/DocumentsTab/Documents'

const DashboardRoutes = [
  {
    path: '/',
    element: DashboardPage,
    childRoutes: [
      {
        path: '',
        element: ChartBox,
      },
      {
        path: 'overview',
        element: ChartBox,
      },
      {
        path: 'transactions',
        element: Transactions,
      },
      {
        path: 'redemptions',
        element: Redemptions,
      },
      {
        path: 'documents',
        element: Documents,
      },
    ],
  },
  {
    path: '/checklist',
    element: DashboardPage,
    isChecklist: true,
  },
  {
    path: '/overview',
    element: DashboardPage,
    isOverview: true,
  },
  {
    path: '/login',
    element: LoginWithOTPFlow,
  },
  { path: '/oops', element: OopsPage },
  {
    path: '/settings/ethereum-address',
    element: EthereumAddress,
  },
  {
    path: '/settings/liquid-address',
    element: LiquidAddress,
  },
  {
    path: '/settings/algorand-address',
    element: AlgorandAddress,
  },
  {
    path: '/settings/profile-and-privacy',
    element: SettingsProfileAndPrivacy,
  },
]

export default DashboardRoutes
