import React from 'react'
import PropTypes from 'prop-types'

import { StyledTab, LinkText } from './TabsNav.styles'

const TabNav = ({ children, isActive, onClick, ...props }) => (
  <StyledTab onClick={onClick} isActive={isActive} {...props}>
    <LinkText>{children}</LinkText>
  </StyledTab>
)

TabNav.propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
}

TabNav.defaultProps = {
  isActive: false,
  onClick: () => {},
}

export default TabNav
