import React from 'react'
import PropTypes from 'prop-types'
import {
  PageWrapper,
  FooterLayout,
  Header,
  AuthContext,
} from '@stokr/components-library'

const Layout = ({
  title,
  progress,
  loginHandler,
  signupHandler,
  signupFlow,
  children,
  footerColor,
  noHeader,
  noFooter,
  withFooterMenu,
  withNewsLetter,
}) => {
  const [activeMenu, setActiveMenu] = React.useState(null)
  const { user, avatar, logoutUser } = React.useContext(AuthContext)

  const handleOutsideMenuClick = (e) => {
    setActiveMenu(false)
  }

  const activateMenu = () => {
    setActiveMenu(null)
  }

  return (
    <PageWrapper>
      {!noHeader && (
        <Header
          activeMenu={activeMenu}
          user={user}
          activateMenu={activateMenu}
          progress={progress}
          useRelativePathForUserMenu
          userPhoto={avatar}
          logoutUser={logoutUser}
        />
      )}
      <div onClick={handleOutsideMenuClick}>{children}</div>
      {!noFooter && (
        <FooterLayout
          color={footerColor}
          withFooterMenu={withFooterMenu}
          withNewsLetter={withNewsLetter}
        />
      )}
    </PageWrapper>
  )
}

Layout.propTypes = {
  title: PropTypes.string,
  signupFlow: PropTypes.bool,
  loginHandler: PropTypes.func,
  signupHandler: PropTypes.func,
  progress: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    doneIndex: PropTypes.number,
    activeIndex: PropTypes.number,
  }),
  footerColor: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  title: '',
  progress: null,
  signupFlow: false,
  footerColor: 'red',
  loginHandler: () => {},
  signupHandler: () => {},
}

export default Layout
