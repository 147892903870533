/* Libraries */
import axios from '../model/axios'
import Cookies from 'js-cookie'
import axiosPublic from 'model/axios-public'

export const fetchFile = async (query, data = undefined) => {
  const auth = await Cookies.get('STOKR_ACCESS_TOKEN')

  const Data = JSON.stringify(data)

  try {
    // Mockup
    // const result = await axios.get(query)

    // Microservices
    const result = await axios.post(query, Data, {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
      responseType: 'blob',
    })

    // Return fetched list to be rendered
    return result
  } catch (error) {
    console.log(error)

    // 'error' is used to render error message
    throw error
  }
}

export const fetchFilePublic = async (query, data = undefined) => {
  const Data = JSON.stringify(data)

  try {
    // Microservices
    const result = await axiosPublic.post(query, Data, {
      responseType: 'blob',
    })

    // Return fetched list to be rendered
    return result
  } catch (error) {
    console.log(error)

    // 'error' is used to render error message
    throw error
  }
}
