import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'

import { Chart, Content } from './DonutChart.styles'

class DonutChart extends PureComponent {
  state = {
    data: [],
    key: 0,
  }

  componentDidMount() {
    const { data } = this.props

    this.setState({ data }, () => {
      this.createChart()
      window.addEventListener('scroll', this.onScroll)
      this.onScroll()
    })
  }

  componentDidUpdate(prevProps) {
    const { key } = this.state
    const { data: newData } = this.props
    const { data: prevData } = prevProps

    if (
      newData &&
      prevData &&
      JSON.stringify(newData) !== JSON.stringify(prevData)
    ) {
      this.setState({ data: newData }, () => {
        this.drawn = false
        window.removeEventListener('scroll', this.onScroll)

        this.setState({ key: key + 1 }, () => {
          this.createChart()
          window.addEventListener('scroll', this.onScroll)
          this.onScroll()
        })
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  createChart = () => {
    if (!this.chartEl) return

    const { data } = this.state

    const width = 320
    const height = 320
    const pad = 10
    const thickness = 14

    const outerRadius = width / 2
    const innerRadius = outerRadius - thickness
    const padAngle = pad / (outerRadius - thickness / 2)

    const customColors = data.map((item) => item.color)
    const color = customColors[0]
      ? d3.scaleOrdinal(customColors)
      : d3.scaleOrdinal(d3.schemeCategory10)

    const svg = d3
      .select(this.chartEl)
      .append('svg')
      .attr('viewBox', `0 0 ${width} ${height}`)

    const chart = svg
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`)

    const pie = d3
      .pie()
      .value((d) => d.value * d.price)
      .sort(null)
      .padAngle(padAngle)

    const arc = d3.arc().outerRadius(outerRadius).innerRadius(innerRadius)

    function arcTween(a) {
      const interp = d3.interpolate(this._current, a)
      this._current = interp(1)
      return (time) => arc(interp(time))
    }

    function setD(d) {
      this._current = d
    }

    this.drawDate = (dataSet) => {
      // Join new data
      const path = chart.selectAll('path').data(pie(dataSet))

      // Update existing arcs
      path
        .transition()
        .ease(d3.easeQuadOut)
        .duration(1000)
        .attrTween('d', arcTween)

      // Enter new arcs
      path
        .enter()
        .append('path')
        .attr('fill', (d, i) => color(i))
        .attr('d', arc)
        .each(setD)
    }

    // set init data
    this.drawDate(
      data.map((datum) => ({
        ...datum,
        value: 0,
      })),
    )
  }

  drawChart = () => {
    const { data } = this.props

    this.drawDate(data)
    this.drawn = true
  }

  onScroll = (e) => {
    if (this.drawn) return
    if (this.chartEl.getBoundingClientRect().top < window.innerHeight) {
      this.drawChart()
    }
  }

  render() {
    const { children, center } = this.props
    const { key } = this.state

    return (
      <Chart
        ref={(el) => {
          this.chartEl = el
        }}
        center={center}
        key={key}
      >
        <Content>{children}</Content>
      </Chart>
    )
  }
}

DonutChart.propTypes = {
  children: PropTypes.node,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      name: PropTypes.string,
      color: PropTypes.string,
    }),
  ).isRequired,
  center: PropTypes.bool,
}

DonutChart.defaultProps = {
  children: null,
  center: false,
}

export default DonutChart
