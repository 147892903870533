import Cookies from 'js-cookie'

export default (path = '') => {
  const inOneHour = 1 / 24

  const cookieOptions = {
    expires: inOneHour,
  }

  if (process.env.NODE_ENV === 'production') {
    cookieOptions.domain = '.stokr-staging.de'
  }

  Cookies.set(
    'STOKR_INVESTOR_DASHBOARD_REDIRECT',
    `${window.location.href}${path}`,
    cookieOptions,
  )
}
