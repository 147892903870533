import styled, { css } from 'styled-components'
import rwd from 'styles/rwd'
import NotificationCounter from 'components/NotificationCounter/NotificationCounter.styles'

export const Button = styled.button`
  display: block;
  width: 100%;
  color: ${(props) => props.theme.cBlack};
  border-bottom: 1px solid ${(props) => props.theme.cLightGrey};
  transition: color 0.2s, border-color 0.2s;

  &:focus,
  &:hover {
    color: ${(props) => props.theme.cPrimary};
  }

  &:focus {
    outline: none;
  }

  & > div {
    width: 100%;
  }

  ${(props) =>
    props.isOpened &&
    `
    border-bottom-color: transparent;
  `}
`

export const Wrapper = styled.div`
  position: relative;
  padding-right: 20px;
  display: flex;
  align-items: center;

  ${rwd.Medium`
    justify-content: center;

    ${(props) =>
      props.textLeft &&
      `
      justify-content: flex-start;
    `}
  `}
`

export const Title = styled.div`
  font-size: 11px;
  line-height: 20px;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
`

export const Counter = styled(NotificationCounter)`
  margin-left: 12px;
  font-weight: 700;
  font-size: 11px;
  line-height: 11px;

  ${({ greenCounter }) =>
    greenCounter &&
    css`
      background-color: #88fa51;
      color: ${(props) => props.theme.cText};
    `}

  ${({ dropdownMenu }) =>
    dropdownMenu &&
    css`
      font-size: 9px;
      line-height: 11px !important;
      padding-left: 2px;
    `}
`

export const Arrow = styled.i.attrs({
  className: 'ion ion-ios-arrow-down',
})`
  &&& {
    position: absolute;
    top: 50%;
    right: 0;
    transition: 0.3s transform;
    transform: rotate(0);
    text-align: center;
    font-size: 16px;
    line-height: 16px;
    height: 16px;
    margin-top: -8px;

    ${(props) =>
      props.isOpened &&
      `
      transform: rotate(180deg);
    `}
  }
`
