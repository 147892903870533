import styled, { css } from 'styled-components'
import rwd from 'styles/rwd'
import {
  Container as BackgroundContainer,
  Content as BackgroundContent,
  Text,
  Button,
} from '@stokr/components-library'
import { CompleteMarkContainer } from './LearnMore.shared.styles'

export const ArticleWrapper = styled.div`
  padding: 32px;
  position: relative;

  ${rwd.Medium`
    padding: 64px;
  `}

  ${rwd.XLarge`
    padding: 128px;
  `}

  ${(props) =>
    props.postType === 'text' &&
    css`
      padding-right: 64px;

      ${rwd.Large`
      padding-right: 128px;
    `}

      ${rwd.XLarge`
      padding-right: 224px;
    `}
    `}

  &::before {
    content: '';
    position: absolute;
    width: 1px;
    background: ${(props) => props.theme.cGrey};

    ${(props) =>
      props.postType === 'video' &&
      css`
        top: 32px;
        left: 48px;
        bottom: 0;

        ${rwd.Medium`
        top: 64px;
        left: 64px;
      `}

        ${rwd.Large`
        left: 96px;
      `}

      ${rwd.XLarge`
        top: 128px;
        left: 160px;
      `}
      `}

    ${(props) =>
      props.postType === 'text' &&
      css`
        top: 0;
        right: 48px;
        bottom: 0;

        ${rwd.Medium`
        right: 32px;
      `}

        ${rwd.Large`
        right: 96px;
      `}

      ${rwd.XLarge`
        right: 192px;
      `}
      `}
  }
`

export const VideoTitleRow = styled.div`
  display: flex;
  flex-direction: column;

  ${rwd.XLarge`
    align-items: center;
    flex-direction: row;
  `}
`

export const VideoCover = styled.div`
  width: 100%;

  ${BackgroundContainer} {
    width: 100%;
    padding-top: 56.25%;
  }

  ${BackgroundContent} {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    transform: translateY(-50%) translateX(-50%);
  }
`

export const VideoPlay = styled.div`
  width: 48px;
  height: 48px;
  color: ${(props) => props.theme.cWhite};
  background: ${(props) => props.theme.cWarning};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s background;

  &:hover {
    color: ${(props) => props.theme.cWhite};
    background: ${(props) => props.theme.cWarningDark};
  }
`

export const VideoPlayIcon = styled.i.attrs({
  className: 'ion ion-md-play',
})`
  font-size: 16px;
`

export const TitleText = styled(Text)`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 6px;
    background: ${(props) => props.theme.cPrimary};
    top: 0;
    bottom: 0;

    ${(props) =>
      props.postType === 'video' &&
      css`
        transform: translateX(1px);
        left: -16px;

        ${rwd.Medium`
        left: -32px;
      `}
      `}

    ${(props) =>
      props.postType === 'text' &&
      css`
        transform: translateX(-1px);
        right: -16px;

        ${rwd.Medium`
        right: -32px;
      `}
      `}
  }
`

export const CompleteWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  margin-left: auto;
  margin-top: 24px;

  ${(props) =>
    props.center &&
    `
    justify-content: center;
  `}

  ${rwd.XLarge`
    margin-top: 0;
  `}

  ${CompleteMarkContainer} {
    flex-shrink: 0;
    margin-left: 16px;
  }

  ${Button} {
    flex-shrink: 0;
  }
`

export const ContentText = styled(Text)`
  display: flex;
  flex-direction: column;

  h5 {
    font-size: 16px;
    letter-spacing: 0.4px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  img {
    max-width: 100%;
    margin: 32px auto;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    font-family: 'Open Sans';
    font-weight: 300;
    font-size: 16px;
    line-height: 1.5em;
    letter-spacing: 0.6px;
    padding-left: 12px;
    position: relative;

    ${rwd.XLarge`
      font-size: 20px;
    `}

    &::before {
      position: absolute;
      left: 0;
      content: '•';
      color: ${(props) => props.theme.cWarning};
    }
  }
`
