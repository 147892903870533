import styled, { css } from 'styled-components'
import {
  Column,
  ComponentWrapper,
  TabsWrapper,
  StyledTabs,
  StyledBackButton,
  BackButtonIcon,
} from '@stokr/components-library'
import rwd from 'styles/rwd'

export const Container = styled.div`
  width: 100%;
  overflow: hidden;

  ${TabsWrapper} {
    padding: 0 !important;
  }

  ${StyledTabs} {
    border-bottom: none;
  }
`

export const TabArrow = styled.i.attrs({
  className: 'ion ion-ios-arrow-forward',
})`
  color: ${(props) => props.theme.cWarning};
  margin-right: 6px;
  transition: 0.3s transform;

  ${(props) =>
    props.isActive === true &&
    `
    transform: rotate(90deg);
  `}
`

export const TitleContainer = styled(ComponentWrapper)`
  display: flex;
  flex-direction: column;

  ${rwd.Medium`
    padding: 64px;
  `}

  ${rwd.Large`
    padding-right: 96px;
  `}

  ${StyledBackButton} {
    color: ${(props) => props.theme.cPrimary};
    text-transform: uppercase;
  }

  ${BackButtonIcon} {
    color: ${(props) => props.theme.cPrimary};
  }
`

export const TabTitle = styled.div`
  padding: 42px 36px;
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 0.6px;
`

export const Arrow = styled.i.attrs({
  className: 'ion ion-ios-arrow-right',
})`
  &&& {
    position: absolute;
    top: 50%;
    right: 32px;
    transition: 0.3s transform;
    transform: rotate(0);
    text-align: center;
    font-size: 16px;
    line-height: 16px;
    height: 16px;
    margin-top: -8px;

    ${(props) =>
      props.isOpened &&
      `
      transform: rotate(-90deg);
    `}
  }
`

export const SubTabNav = styled(ComponentWrapper)`
  display: flex;
  align-items: center;
  font-size: 10px;
  cursor: pointer;
  transition: 0.3s background;
  background: ${(props) => props.theme.cWhite};

  &::before {
    content: '';
    display: none;
    position: absolute;
    top: 52px;
    bottom: -32px;
    right: 42px;
    z-index: 1;
    width: 0;
    border-left: 1px dotted ${(props) => props.theme.cLightGrey};
    transform: translateX(50%);

    ${rwd.Medium`
      display: block;
    `}

    ${rwd.Large`
      right: 74px;
    `}
  }

  &:last-child::before {
    display: none;
  }

  ${(props) =>
    props.isActive &&
    css`
      font-weight: 700;
      background: ${props.theme.cGrey};
    `}
`

export const TabNav = styled(ComponentWrapper)`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  cursor: pointer;

  &:last-child {
    border-bottom: 1px solid ${(props) => props.theme.cLightGrey};
  }

  ${(props) =>
    props.isActive &&
    css`
      &::before {
        content: '';
        width: 8px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background: ${props.theme.cWarning};
      }
    `}

  + div ${SubTabNav}::before {
    ${(props) =>
      props.complete &&
      css`
        border-color: ${props.theme.cLighterGreen};
        border-left-style: solid;
      `}
  }
`

export const SidebarColumn = styled(Column)`
  max-width: 300px;
  position: fixed;
  background: ${(props) => props.theme.cWhite};
  z-index: 10000;
  border-right: 1px solid ${(props) => props.theme.cLightGrey};
  top: 0;
  padding-top: 48px;
  transition: 0.3s transform;
  transform: translateX(-100%);
  ${(props) => props.isExpanded && 'transform: translateX(0);'}

  ${rwd.Medium`
    transform: translateX(0);
    max-width: 300px;
    padding-top: ${(props) => props.theme.headerHeight}px;
  `}

  ${rwd.Large`
    max-width: 400px;
  `}
`

export const ContentColumn = styled(Column)`
  ${rwd.Medium`
    margin-left: 300px;
  `}

  ${rwd.Large`
    margin-left: 400px;
  `}
`

export const SidebarTitle = styled.h3`
  font-family: 'Open Sans';
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 20px;
`

export const SidebarHeading = styled.div`
  font-size: 12px;
  letter-spacing: 0.4px;
  font-weight: 600;
  margin-bottom: 16px;
`

export const MobileMenuToggler = styled.div`
  display: block;
  width: 48px;
  height: 48px;
  position: fixed;
  top: 0;
  left: 0;
  background: ${(props) => props.theme.cWhite};
  z-index: 10001;

  ${rwd.Medium`
    display: none;
  `}

  span {
    display: block;
    width: 16px;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -1px;
    margin-left: -8px;
    background-color: ${(props) => props.theme.cText};
    transition: background-color 0.4s, transform 0.4s;

    &:nth-child(1) {
      transform: translateY(-6px);
    }

    &:nth-child(3) {
      transform: translateY(6px);
    }
  }

  ${(props) =>
    props.isActive &&
    `
    span {
      background-color: ${props.theme.cPrimary};

      &:nth-child(1) {
        transform: translateY(0) rotate(135deg);
      }

      &:nth-child(2) {
        transform: scaleX(0);
      }

      &:nth-child(3) {
        transform: translateY(0) rotate(-135deg);
      }
    }
  `}
`
