import styled from 'styled-components'
import rwd from 'styles/rwd'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  flex-grow: 1;
  background-color: ${(props) => props.theme.cPrimary};
  color: ${(props) => props.theme.cWhite};
  padding: 32px;

  ${(props) =>
    props.withBorder &&
    `
    box-shadow: inset 0 0.4px 0 0 #313131;
  `}

  ${rwd.Medium`
    padding: 12px 32px;
    text-align: left;
  `}

  ${rwd.Large`
    padding: 12px 64px;
    text-align: left;
  `}

  @media screen and (max-width: 768px) {
    max-width: 100vw;
  }
`

export const Label = styled.div`
  font-size: 11px;
  line-height: 2.18;
  letter-spacing: 2.1px;
  text-transform: uppercase;
  margin-bottom: 10px;

  ${(props) =>
    props.withBorder &&
    `
    color: #88fa51;
  `}
`

export const Value = styled.div`
  font-weight: 300;
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 2.43px;
  text-transform: uppercase;

  & > span {
    &:after {
      content: ' ';
    }
  }

  ${rwd.Medium`
    font-size: 44px;
    line-height: 48px;
  `}

  ${rwd.Large`
    font-size: 55px;
    line-height: 58px;
  `}
`

export const Equal = styled.div`
  font-size: 11px;
  line-height: 2.18;
  letter-spacing: 2.1px;
  text-transform: uppercase;
  margin-top: 10px;
  color: ${(props) => props.theme.cLighterGreen};
  overflow-wrap: break-word;

  & > span {
    &:after {
      content: ' ';
    }
  }
`
