export const checkTodoStatus = (user) => {
  const isFromAllowedCountry = user.countryObj?.isAllowed

  let count = 0

  // Country
  if (
    isFromAllowedCountry &&
    (user.user_type === 'investor' || user.user_type === 'investor-entity')
  ) {
    //wallet (we only check for liquid wallet)
    !user?.wallets?.some((wallet) => wallet.type === 'liquid') && count++
    // Risk Questionnaire
    !user.is_risk_questionnaire_finished && count++
    // KYC
    ;(!user.kyc_status ||
      user.kyc_status.toUpperCase() === 'DRAFT' ||
      user.kyc_status.toUpperCase() === 'REAPPLY') &&
      count++
  }

  return count
}

export default checkTodoStatus
